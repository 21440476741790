import Slider from '#components/shared/ui/Slider';
import Typography from '#components/shared/ui/Typography';
import type { WithTranslations } from '#i18n/index.ts';
import { type FC } from 'react';
import styles from './LookupHow.module.scss';

type Props = {
  images: Array<{ src: any; height: number; width: number }>;
};

const LookupHowMobile: FC<WithTranslations<Props>> = ({ t, images }) => {
  return (
    <div className={styles.slider}>
      <Slider
        slides={t.lookups.shared.howCards}
        renderSlide={(card, idx) => (
          <div key={card.title} className={styles.card}>
            <div className={styles.card_content}>
              <Typography component="h3" variant="h4" className={styles.card_title}>
                {card.title}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                color="secondary"
                className={styles.card_description}
              >
                {card.description}
              </Typography>
              <Typography
                component="a"
                variant="h6"
                color="accent"
                style={{ zIndex: '1' }}
                href={card.link}
                className={styles.card_link}
              >
                {card.label}
              </Typography>
            </div>
            <img
              {...images[idx]}
              className={styles.card_img}
              alt={card.label}
              src={images[idx].src.src}
            />
          </div>
        )}
      />
    </div>
  );
};

export default LookupHowMobile;
